<template>
  <v-theme-provider dark>
    <base-section
      id="warranty"
      class="black px-4"
      space="40"
      dark
    >
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="4"
            class="text-center"
          >
            <v-img
              src="@/assets/Garantia.jpg"
              max-height="350"
              contain
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="8"
          >
            <base-title
              space="4"
              title="Ainda em dúvida se isso é REALMENTE um método diferente? Te garanto que não existe o menor risco de você sair perdendo!"
            />

            <base-body space="2">
              <span class="text-justify">
                <p>Nós montamos JUNTOS como será o seu plano alimentar antes mesmo de eu te enviá-lo, se por algum motivo você perceber que o seu plano alimentar não é aquilo que você esperava eu te ofereço um reembolso de 100% do seu dinheiro.</p>
                <p>Não gostou? Você não perderá nada do seu dinheiro, eu devolverei tudo e farei isso rápido, sem questionamentos ou letrinhas miúdas.</p>
              </span>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionWarranty',
  }
</script>
